import { FunctionComponent, useEffect, useState } from "react";

import {
  GetPensionDataResponse,
  dataLifePensionMove,
} from "../../../../../data/dataLifePensionMove";
import { useTransfer } from "../TransferContext";
import { SadPath } from "../components/sadPath/SadPath";
import { HappyPath } from "../components/happyPath/HappyPath";

interface Props {
  next: () => void;
  toBack: () => void;
  toNotMovable: () => void;
}

export const InsuranceInformationSummary: FunctionComponent<Props> = ({
  next,
  toBack,
  toNotMovable,
}) => {
  const [transfer] = useTransfer();
  const [scrapedPensionData, setScrapedPensionData] =
    useState<GetPensionDataResponse>();
  const [checked, setChecked] = useState<{ [id: string]: boolean }>({});

  useEffect(() => {
    dataLifePensionMove.getPensionData().then((pensionData) => {
      const newChecked: { [id: string]: boolean } = {};

      pensionData?.movability?.["MOVABLE"]?.forEach((movableMove) => {
        newChecked[movableMove.id] = true;
      });
      transfer.moves?.forEach((manualMove) => {
        newChecked[manualMove.id as string] = true;
      });
      setChecked(newChecked);
      setScrapedPensionData(pensionData);
    });
  }, [transfer.moves]);

  const movable = scrapedPensionData?.movability?.["MOVABLE"];
  const unknown = scrapedPensionData?.movability?.["UNKNOWN"];
  const notMovable = scrapedPensionData?.movability?.["NOT_MOVABLE"];
  const noPensionFound = scrapedPensionData?.noPensionsFound
    ? Object.keys(scrapedPensionData?.noPensionsFound)
    : [];

  const manual = transfer.moves;

  const hasManualAddedPensions = transfer.moves?.length > 0;
  const hasPotentiallyMovablePensions =
    movable?.length || unknown?.length || hasManualAddedPensions;
  const notMovableCount =
    (notMovable?.length ?? 0) + (noPensionFound?.length ?? 0);

  if (!hasPotentiallyMovablePensions) {
    return (
      <SadPath
        notMovableCount={notMovableCount}
        toNotMovable={toNotMovable}
        toBack={toBack}
      />
    );
  }

  return (
    <HappyPath
      movable={movable}
      unknown={unknown}
      manual={manual}
      checked={checked}
      setChecked={setChecked}
      notMovableCount={notMovableCount}
      next={next}
      toNotMovable={toNotMovable}
    />
  );
};
