import { FunctionComponent } from "react";
import { NewIcon, SelectableCard, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";

import "./LinkCard.scss";

interface Props {
  count?: number;
  onClick: () => void;
}

export const LinkCard: FunctionComponent<Props> = ({ count, onClick }) => {
  return (
    <SelectableCard
      className="transfer-pension-link-card"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }}
    >
      <header className="header">
        <section className="start">
          <div
            className="counter-circle"
            role="status"
            aria-label={`Counter: ${count}`}
          >
            <span className="counter-value">{count}</span>
          </div>
          <Typography type="label" className="label">
            <TranslatedText
              id={"sweden.transfer-pension.not-movable-card.header"}
            />
          </Typography>
        </section>
        <section className="end">
          <NewIcon.ChevronRight />
        </section>
      </header>
    </SelectableCard>
  );
};
