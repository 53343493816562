import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from "react";

import { NewIcon, Tickbox, Typography } from "@lysaab/ui-2";
import {
  Information,
  InsurelyPensionType,
} from "../../../../../../data/dataLifePensionMove";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { PensionLogo } from "../../../../../../components/pensionLogo/PensionLogo";
import { AccountType } from "../../../../../../data/dataAccounts";
import { Amount } from "../../../../../../components/amount/Amount";
import { Modal } from "../../../../../../components/modal/Modal";
import { defineMessages, useIntl } from "react-intl";
import { ReadMoreButton } from "./ReadMoreButton";

import "./MoveCard.scss";

/**
 * Read more is additional information the user display by clicking Read more. The information lives solely in the frontend. It is
 * based on which of the "movability" array's the move is from, the "information" property in the move and the type of move.
 * Thus, require a bit of juggling to compose. Hence the dance.
 */

type ReadMore =
  | "COLLECTIVE"
  | "ACTIVE_PAYMENT_UNKNOWN"
  | "ACTIVE_PAYOUT_UNKNOWN"
  | "ACTIVE_PAYMENT_NOT_MOVABLE"
  | "ACTIVE_PAYOUT_NOT_MOVABLE"
  | "TRADITIONALLY_MANAGED"
  | null;

type List = "MOVABLE" | "UNKNOWN" | "NOT_MOVABLE" | "MANUAL";

const messages = defineMessages({
  collective: {
    id: "sweden.transfer-pension.not-movable.read-more.collective.header",
  },
  activePaymentUnkown: {
    id: "sweden.transfer-pension.not-movable.read-more.active-payment.unknown.header",
  },
  activePayoutUnkown: {
    id: "sweden.transfer-pension.not-movable.read-more.active-payout.unknown.header",
  },
  activePaymentNotMovable: {
    id: "sweden.transfer-pension.not-movable.read-more.active-payment.not-movable.header",
  },
  activePayoutNotMovable: {
    id: "sweden.transfer-pension.not-movable.read-more.active-payout.not-movable.header",
  },
  traditional: {
    id: "sweden.transfer-pension.not-movable.read-more.traditional.header",
  },
});

const readMoreMap = {
  COLLECTIVE: {
    header: messages.collective,
    component: <CollectiveInformation />,
  },
  ACTIVE_PAYMENT_UNKNOWN: {
    header: messages.activePaymentUnkown,
    component: <ActivePaymentUnknownInformation />,
  },
  ACTIVE_PAYOUT_UNKNOWN: {
    header: messages.activePaymentUnkown,
    component: <ActivePayoutUnknownInformation />,
  },
  ACTIVE_PAYMENT_NOT_MOVABLE: {
    header: messages.activePaymentNotMovable,
    component: <ActivePaymentNotMovableInformation />,
  },
  ACTIVE_PAYOUT_NOT_MOVABLE: {
    header: messages.activePayoutNotMovable,
    component: <ActivePayoutNotMovableInformation />,
  },
  TRADITIONALLY_MANAGED: {
    header: messages.traditional,
    component: <TraditionallyManagedInformation />,
  },
};

interface Props {
  insuranceCompany?: string;
  insuranceNumber?: string;
  insuranceHolderName?: string;
  insuranceHolderTin?: string;
  currentWorth?: number;
  information?: Information;
  checked?: boolean;
  handleChange?: () => void;
  moveAccountType?: AccountType | InsurelyPensionType;
  list?: List;
}

export const MoveCard: FunctionComponent<Props> = ({
  insuranceCompany,
  insuranceNumber,
  insuranceHolderName,
  insuranceHolderTin,
  currentWorth,
  information,
  checked,
  handleChange,
  moveAccountType,
  list,
}) => {
  const [readMore, setReadMore] = useState<ReadMore>(null);
  const intl = useIntl();

  if (!insuranceCompany) {
    return null;
  }

  // Derived state for determining the information source
  const infoSource =
    moveAccountType === "COLLECTIVE_OCCUPATIONAL_PENSION"
      ? "FROM_TYPE"
      : information && Object.keys(information).length > 0
      ? "FROM_SCRAPING"
      : null;

  return (
    <section className="pension-move-card">
      <header className="header">
        <div className="company">
          <PensionLogo pension={{ key: insuranceCompany }} />
          <div className="name-and-worth">
            <Typography type="label-large" className="institute">
              {getReadableName(insuranceCompany)}
            </Typography>
            {currentWorth && currentWorth === -1 && (
              <TranslatedText
                id={"sweden.transfer-pension.move-card.currentWorth.unkown"}
              />
            )}
            {currentWorth && currentWorth !== -1 && (
              <Amount amount={currentWorth} />
            )}
          </div>
        </div>
        {handleChange && (
          <Tickbox
            size={18}
            checked={checked ? checked : false}
            alternative={{
              text: "",
              value: insuranceCompany,
            }}
            onChange={() => handleChange()}
            validators={[]}
          />
        )}
      </header>
      {(insuranceNumber || insuranceHolderName) && <hr />}
      {insuranceNumber && (
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.move-card.insuranceNumber.label"}
            />
          </dt>
          <dd>{insuranceNumber}</dd>
        </dl>
      )}
      {(insuranceHolderName || insuranceHolderTin) &&
        moveAccountType !== "PRIVATE_PENSION" && (
          <dl>
            <dt>
              <TranslatedText
                id={
                  "sweden.transfer-pension.move-card.insuranceHolderName.label"
                }
              />
            </dt>
            <dd>
              {insuranceHolderName ? insuranceHolderName : insuranceHolderTin}
            </dd>
          </dl>
        )}
      {moveAccountType && moveAccountType === AccountType.LYSA_PPF && (
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.move-card.ppf.label"}
            />
          </dt>
        </dl>
      )}
      {infoSource && <hr className="information-divider"></hr>}
      {infoSource === "FROM_TYPE" && (
        <section>
          <li className="information">
            <span>
              <NewIcon.Warning
                className="icon"
                size={24}
                primaryColor="var(--lysa-text-primary)"
              />
            </span>
            <section className="information-from-type-content">
              <Typography type="body" className="information-message-header">
                <InformationMessageHeader messageKey="COLLECTIVE" />
              </Typography>
              <Typography type="body" className="information-message">
                <InformationMessage
                  messageKey={"COLLECTIVE"}
                  setReadMore={setReadMore}
                />
              </Typography>
            </section>
          </li>
        </section>
      )}
      {infoSource === "FROM_SCRAPING" && information && (
        <ul className="information-list">
          {Object.entries(information)
            .sort(warningsFirstCompareFn)
            .map(([key, severity]) => {
              return (
                <li key={key}>
                  <section className="information">
                    <span>
                      {severity === "INFO" ? (
                        <NewIcon.Information
                          className="icon"
                          size={24}
                          primaryColor="var(--lysa-text-primary)"
                        />
                      ) : (
                        <NewIcon.Warning
                          className="icon"
                          size={24}
                          primaryColor="var(--lysa-text-primary)"
                        />
                      )}
                    </span>
                    <section
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        type="body"
                        className="information-message-header"
                      >
                        <InformationMessageHeader messageKey={key} />
                      </Typography>
                      <Typography type="body" className="information-message">
                        <InformationMessage
                          messageKey={key}
                          setReadMore={setReadMore}
                          list={list}
                        />
                      </Typography>
                    </section>
                  </section>
                </li>
              );
            })}
        </ul>
      )}
      {readMore && (
        <Modal
          header={intl.formatMessage(readMoreMap[readMore].header)}
          showModal={!!readMore}
          onClose={() => {
            setReadMore(null);
          }}
          closeOnOverlayClick
        >
          {readMoreMap[readMore].component}
        </Modal>
      )}
    </section>
  );
};

export const getReadableName = (name: string) => {
  if (name === "AMF") {
    return "AMF";
  }
  if (name === "ALECTA") {
    return "Alecta";
  }
  if (name === "AVANZA") {
    return "Avanza";
  }
  if (name === "FOLKSAM") {
    return "Folksam";
  }
  if (name === "FUTUR") {
    return "Futur";
  }
  if (name === "HANDELSBANKEN") {
    return "Handelsbanken";
  }
  if (name === "IDUN_LIV") {
    return "Idun Liv";
  }
  if (name === "KPA") {
    return "KPA";
  }
  if (name === "LANSFORSAKRINGAR") {
    return "Länsförsäkringar";
  }
  if (name === "NORDEA") {
    return "Nordea";
  }
  if (name === "NORDNET") {
    return "Nordnet";
  }
  if (name === "MOVESTIC") {
    return "Movestic";
  }
  if (name === "SEB") {
    return "SEB";
  }
  if (name === "SKANDIA") {
    return "Skandia";
  }
  if (name === "SPP") {
    return "SPP";
  }
  if (name === "SVENSKA_FRIBREVSBOLAGET") {
    return "Svenska Fribrevsbolaget";
  }
  if (name === "SWEDBANK") {
    return "Swedbank";
  }
  return name;
};

const InformationMessageHeader = ({ messageKey }: { messageKey: string }) => {
  if (messageKey === "COLLECTIVE") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.collective.header"}
      />
    );
  }
  if (messageKey === "ACTIVE_PAYMENT") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.active-payment.header"}
      />
    );
  }
  if (messageKey === "TRADITIONALLY_MANAGED") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.traditionally_managed.header"}
      />
    );
  }
  return null;
};

const InformationMessage = ({
  messageKey,
  setReadMore,
  list,
}: {
  messageKey: string;
  setReadMore?: Dispatch<SetStateAction<ReadMore>>;
  list?: List;
}) => {
  /**
   * Handles the event triggered by clicking the "Read More" button.
   *
   * @param event - The mouse event triggered by the click.
   * @param informationType - A string representing the informationType of action to handle (e.g., 'COLLECTIVE', 'ACTIVE').
   * @returns void
   *
   * event.preventDefault() and event.stopPropagation() is needed to block event
   * from bubbling up to any form that might use the MoveCard component.
   *
   */
  function handleReadMore(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    informationType: ReadMore
  ): void {
    event.preventDefault();
    event.stopPropagation();
    if (!informationType || !setReadMore) {
      return;
    } else {
      setReadMore(informationType);
    }
  }

  if (messageKey === "INSURED_NAME_MISMATCH") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.insured-name-mismatch"}
      />
    );
  }
  if (messageKey === "NOT_SUPPORTED_TYPE") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.not-supported-type"}
      />
    );
  }
  if (messageKey === "COLLECTIVE") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.collective"}
        values={{
          readMore: (text) => {
            return (
              <ReadMoreButton
                text={text}
                onClick={(event) => handleReadMore(event, "COLLECTIVE")}
              />
            );
          },
        }}
      />
    );
  }
  if (messageKey === "ACTIVE_PAYMENT") {
    if (list === "UNKNOWN") {
      return (
        <TranslatedText
          id="sweden.transfer-pension.move-card.active-payment.unknown"
          values={{
            readMore: (text) => {
              return (
                <ReadMoreButton
                  text={text}
                  onClick={(event) =>
                    handleReadMore(event, "ACTIVE_PAYMENT_UNKNOWN")
                  }
                />
              );
            },
          }}
        />
      );
    }
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.active-payment"}
        values={{
          readMore: (text) => {
            return (
              <ReadMoreButton
                text={text}
                onClick={(event) =>
                  handleReadMore(event, "ACTIVE_PAYMENT_NOT_MOVABLE")
                }
              />
            );
          },
        }}
      />
    );
  }
  if (messageKey === "ACTIVE_PAYOUT") {
    if (list === "UNKNOWN") {
      return (
        <TranslatedText id="sweden.transfer-pension.move-card.active-payout.unknown" />
      );
    }
    return (
      <TranslatedText id={"sweden.transfer-pension.move-card.active-payout"} />
    );
  }
  if (messageKey === "MISSING_INSURANCE_HOLDER_DATA") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.missing-insurance-holder-data"}
      />
    );
  }
  if (messageKey === "NO_RESULT") {
    return (
      <TranslatedText id={"sweden.transfer-pension.move-card.no-result"} />
    );
  }
  if (messageKey === "TRADITIONALLY_MANAGED") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.traditionally-managed"}
        values={{
          readMore: (text) => {
            return (
              <ReadMoreButton
                text={text}
                onClick={(event) =>
                  handleReadMore(event, "TRADITIONALLY_MANAGED")
                }
              />
            );
          },
        }}
      />
    );
  }
  if (messageKey === "INDIVIDUAL_COMPANY") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.individual-company"}
      />
    );
  }
  if (messageKey === "INSURANCE_NUMBER_MISSING") {
    return (
      <TranslatedText
        id={"sweden.transfer-pension.move-card.insurance-number-missing"}
      />
    );
  }
  return null;
};

type InfoEntry = [string, "INFO" | "WARNING"];

function warningsFirstCompareFn(a: InfoEntry, b: InfoEntry) {
  if (a[1] === "INFO" && b[1] === "WARNING") {
    return 1;
  }
  if (a[1] === "WARNING" && b[1] === "INFO") {
    return -1;
  }
  return 0;
}

function CollectiveInformation() {
  return (
    <>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.collective.section-one.body"
          }
        />
      </Typography>
      <Typography type="h3">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.collective.section-two.header"
          }
        />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.collective.section-two.body.one"
          }
        />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.collective.section-two.body.two"
          }
        />
      </Typography>
      <footer className="read-more-collective-footer">
        <Typography type="body">
          <TranslatedText
            id={
              "sweden.transfer-pension.not-movable.read-more.collective.footer"
            }
            values={{
              link: (text) => {
                return (
                  <a
                    className="snackbar-link"
                    target="_blank"
                    href="https://www.minpension.se/"
                    rel="noreferrer"
                  >
                    <strong>{text}</strong>
                  </a>
                );
              },
            }}
          />
        </Typography>
      </footer>
    </>
  );
}

function ActivePaymentNotMovableInformation() {
  return (
    <>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.active-payment.body.one"
          }
        />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.active-payment.body.two"
          }
        />
      </Typography>
    </>
  );
}

function ActivePaymentUnknownInformation() {
  return (
    <>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.unknown.read-more.active-payment.body.one"
          }
        />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.unknown.read-more.active-payment.body.two"
          }
        />
      </Typography>
    </>
  );
}

function ActivePayoutUnknownInformation() {
  return (
    <>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.unknown.read-more.active-payout.body.one"
          }
        />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.unknown.read-more.active-payout.body.two"
          }
        />
      </Typography>
    </>
  );
}

function ActivePayoutNotMovableInformation() {
  return (
    <>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.active-payout.body.one"
          }
        />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.active-payout.body.two"
          }
        />
      </Typography>
    </>
  );
}

function TraditionallyManagedInformation() {
  return (
    <>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.traditionally-managed.body.one"
          }
        />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.not-movable.read-more.traditionally-managed.body.two"
          }
        />
      </Typography>
    </>
  );
}
