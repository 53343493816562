import { FunctionComponent } from "react";

import { useIntl } from "react-intl";
import { Button, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { NotMovablePensionsCard } from "../notMovablePensionsCard/NotMovablePensionsCard";

/**
 * SAD PATH :(
 *
 * Sad path means we DID NOT find any potential movable pensions from scraping.
 * If the user has added pension manually in the previous step we make it possible to continue with only manually added.
 * The manually added is already stored in context so no need to do something extra.
 */

interface Props {
  notMovableCount: number;
  toNotMovable: () => void;
  toBack: () => void;
}

export const SadPath: FunctionComponent<Props> = ({
  notMovableCount,
  toNotMovable,
  toBack,
}) => {
  const intl = useIntl();

  return (
    <article className="transfer-pension-sad-path">
      <Typography type="h2">
        <TranslatedText id="sweden.transfer-pension.sad-path.header" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.sad-path.body.one" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.sad-path.body.two" />
      </Typography>
      <NotMovablePensionsCard count={notMovableCount} onClick={toNotMovable} />
      <Button
        block
        variant="secondary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.sad-path.back-button",
        })}
        onClick={() => {
          toBack();
        }}
      />
    </article>
  );
};
