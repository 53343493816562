import { FunctionComponent } from "react";
import { LinkCard } from "../linkCard/LinkCard";

interface Props {
  count: number;
  onClick: () => void;
}

export const NotMovablePensionsCard: FunctionComponent<Props> = ({
  count,
  onClick,
}) => {
  /**
   * Only show card if not movable pensions exists
   */
  if (count === 0) {
    return null;
  }
  return <LinkCard count={count} onClick={onClick} />;
};
